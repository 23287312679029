.loading-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  /* min-height: 70vh; */
}

.inner-loading-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.loader-image {
  width: 150px;
  height: 150px;
  animation: zoom-in 0.8s;
}
